import { Hero } from "../../sections/hero"
import { NavBar } from "../common/Navbar"


export const Header = () => {
    return (
        <>
            <NavBar/>
            <Hero />   
        </>
    )
}