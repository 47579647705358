import { ContactForm } from '../../../sections/ContactForm';
import { ContentSection } from '../../../sections/ContentSection';
import { FAQS } from '../../../sections/FAQS';
import { Footer } from '../../template/Footer';
import { Header } from '../../template/Header';
import './home.css';

export const Home = () => {
    return (
        
        <div className={"flex flex-col min-h-screen items-center justify-center dark:bg-gray-800 dark:text-white"}>
            <Header/>
            <ContentSection />
            <FAQS/>
            <ContactForm />
            <Footer/>
            
        </div>
    )
}